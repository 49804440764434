import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { MovieContext } from "./App";
/* TODO: 
✔1. show all info on this page
2. format info with css specifically for this page *** include support for horizontal viewport on mobile
3. back button to goto main app again -> or last shown filtered list with indexed page cached
4. play movie button
 */
const MovieInfo = (props) => {
  const MovieInfoContext = useContext(MovieContext);
  const [showFileInfo, setShowFileInfo] = useState(false);
  const dropdownClick = () => {
    setShowFileInfo(!showFileInfo);
  };
  /*   const showActorMovies = (actor) => {
    props.setFilter({ actor: actor });
    MovieInfoContext.setShowMovieInfo([]);
  };
  const showDirectorMovies = () => {
    props.setFilter({ director: props.movieInfo?.Director });
    MovieInfoContext.setShowMovieInfo([]);
  }; */
  const goBack = () => {
    MovieInfoContext.setShowMovieInfo([]);
    // TODO: back doesn't restore state of filters in list.
  };
  const playMovie = () => {
    console.log("play movie");
    MovieInfoContext.setIsPlaying("anything");
    MovieInfoContext.setShowMovieInfo([]);
  };
  return (
    <div className="movie-info-pane">
      <div className="movie-info">
        <div>
          <div className="top">{props.movieInfo?.release_date.substring(0, 4)}</div>
          <div>{props.movieInfo?.runtime}</div>
        </div>
        <div className="movie-title">{props.movieInfo?.title}</div>
        <div>
          <div className="top">
            <span role="img" aria-label="star">
              ⭐
            </span>
            {props.movieInfo?.vote_average}
          </div>
          <div>({props.movieInfo?.vote_count})</div>
        </div>
      </div>
      <div className="list-links">
        <div className="large">Genres: {props.movieInfo?.genres.map((genre) => genre.name).join(", ")}</div>
        <div /* onClick={() => showDirectorMovies()} */>Director: ?</div>
        <div>
          <span>Actors: </span>
          {props.movieInfo?.cast
            .map((actor) => (
              <span
                key={actor.id}
                /* onClick={() => {
                showActorMovies(actor.id);
              }} */
              >
                {actor.name + " (" + actor.character + ")"},
              </span>
            ))
            .join(", ")}
        </div>
      </div>
      <div className="poster-container">
        <button className="play-button" onClick={() => playMovie()} disabled>
          ▶
        </button>
        <img className="poster-fullSize" loading="lazy" src={"https://image.tmdb.org/t/p/original/" + props.movieInfo?.backdrop_path} alt="movie poster" />
      </div>
      <div className="bottom">
        <div>{props.movieInfo.overview}</div>
        <div>
          <button onClick={() => goBack()}>
            Go Back
            <span role="img" aria-label="back">
              ⏪
            </span>
          </button>
          <span role="img" hidden aria-label="dropdown" onClick={() => dropdownClick()}>
            {showFileInfo ? "🔼" : "🔽 Show File Info"}
          </span>
        </div>
        <div className={showFileInfo ? "file-info-shown" : "file-info-hidden"}>
          <div>{`${props.fileInfo.VideoResoltion} ${props.fileInfo.AudioCodecFriendly} ${props.fileInfo.AudioChannels} `}</div>
          <div>Size: {(props.fileInfo.FileSize / 1000 / 1000 / 1000).toFixed(2)} GB</div>
          <div>{props.fileInfo.BitRate / 1000 / 1000}MBps</div>
          <div>Date Added: {props.fileInfo.CreationTime}</div>
        </div>
      </div>
    </div>
  );
};
export default MovieInfo;
MovieInfo.propTypes = {
  movieInfo: PropTypes.shape({
    poster_path: PropTypes.string.isRequired,
    vote_average: PropTypes.number.isRequired,
    vote_count: PropTypes.number.isRequired,
    genres: PropTypes.array.isRequired,
    cast: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    tagline: PropTypes.string,
    runtime: PropTypes.number.isRequired,
    release_date: PropTypes.string.isRequired /* TODO:
    Rated: PropTypes.string.isRequired, */,
    overview: PropTypes.string.isRequired,
    budget: PropTypes.number,
    original_language: PropTypes.string.isRequired,
    production_companies: PropTypes.array,
  }),
  fileInfo: PropTypes.shape({
    VideoResoltion: PropTypes.string.isRequired,
    FileSize: PropTypes.number.isRequired,
    BitRate: PropTypes.number.isRequired,
    AudioCodecFriendly: PropTypes.string.isRequired,
    AudioCodec: PropTypes.string.isRequired,
    AudioChannels: PropTypes.string.isRequired,
    CreationTime: PropTypes.string.isRequired,
    FileName: PropTypes.string.isRequired,
  }),
};
