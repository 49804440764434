import React, { useContext } from "react";
import PropTypes from "prop-types";

import { MovieContext } from "./App";
const VideoPlayer = (props) => {
  const MovieInfo = useContext(MovieContext);
  const playFile = (event) => {
    console.log(event);
  };
  const quitPlayer = () => {
    MovieInfo.setIsPlaying("");
  };
  return (
    <div className="player-container">
      <video controls autoPlay src={props.src} onCanPlay={(e) => playFile(e)}></video>
      <div onClick={() => quitPlayer()}>
        Go Back{" "}
        <span role="img" aria-labelledby="go back">
          🔙
        </span>
      </div>
    </div>
  );
};
export default VideoPlayer;
VideoPlayer.propTypes = {
  src: PropTypes.string.isRequired,
};
