import React, { useContext } from "react";
import PropTypes from "prop-types";
import { MovieContext } from "./App";
const Movie = (props) => {
  const MovieInfo = useContext(MovieContext);
  const showInfo = () => {
    const index = MovieInfo.files.map((e) => e.Id).indexOf(props.file.Id);
    MovieInfo.setShowMovieInfo([index, props.file.MovieApiId]);
  };
  function genresToString(genreArr) {
    if (genreArr) {
      const genres = genreArr.map((genre) => genre.name).join(", ");
      return genres;
    } else {
      return "";
    }
  }
  return (
    <li className="movie-box" onClick={() => showInfo()}>
      <div className="top-bar">
        <div className="year">
          <div>{props.movie?.release_date.substring(0, 4)}</div>
          <div>{props.movie?.runtime} Min</div>
        </div>
        <div className="ratings">
          <div>
            <span role="img" aria-label="star">
              ⭐
            </span>
            {props.movie?.vote_average}
          </div>
          <div>({props.movie?.vote_count})</div>
        </div>
      </div>
      <div className="movie-poster-container">
        <img
          className="movie-poster"
          loading="lazy"
          src={props.movie?.poster_path ? "https://image.tmdb.org/t/p/" + (props.isMobile ? "w185/" : "w500/") + props.movie?.poster_path : ""}
          alt="movie poster"
        />
      </div>
      <div className="bottom-bar">
        <div className="title">
          <span>{props.movie?.title}</span>
        </div>
        <div className="scrolling-textBox">
          <span>{genresToString(props.movie?.genres)}</span>
        </div>
      </div>
    </li>
  );
};

export default Movie;
Movie.propTypes = {
  index: PropTypes.number.isRequired,
  movie: PropTypes.shape({
    poster_path: PropTypes.string.isRequired,
    vote_average: PropTypes.number.isRequired,
    vote_count: PropTypes.number.isRequired,
    genres: PropTypes.array.isRequired,
    cast: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    runtime: PropTypes.number.isRequired,
    release_date: PropTypes.string.isRequired,
  }),
  file: PropTypes.shape({
    VideoResoltion: PropTypes.string,
    FileSize: PropTypes.number.isRequired,
    AudioCodecFriendly: PropTypes.string.isRequired,
    AudioCodec: PropTypes.string.isRequired,
    CreationTime: PropTypes.string.isRequired,
  }),
};
