import React, { useState, useEffect } from "react";
import firebase from "firebase/app";
import "firebase/database";
import firebaseConfig from "./firebase.config";
import "./App.css";
import VideoPlayer from "./VideoPlayer";
import MovieList from "./MovieList";
import MovieInfo from "./MovieInfo";
// IN DEV MODE - import movie info file instead of using firebase to save bandwidth
if (process.env.NODE_ENV === "development") {
  var Movies = require("./TMDB_4-13-2021.json");
  var Files = require("./Files_4-13-2021.json");
}
firebase.initializeApp(firebaseConfig);
export const MovieContext = React.createContext(null);
const App = () => {
  const db = firebase.database();
  //const [lastUpdateDate, setLastUpdateDate] = useState({});
  const [files, setFiles] = useState({});
  const [movies, setMovies] = useState({});
  const [isPlaying, setIsPlaying] = useState("");
  const [showMovieInfo, setShowMovieInfo] = useState([]);
  const [filter, setFilter] = useState({});
  useEffect(() => {
    //fetchLastUpdateDate();
    if (process.env.NODE_ENV === "development") {
      loadDevDataFromJson();
    } else if (process.env.NODE_ENV === "production") {
      fetchFiles();
      fetchMovieInfo();
    }
    return () => {
      //db.ref(`LastUpdateDate/`).off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadDevDataFromJson = () => {
    /* let myFiles = [];
    Object.keys(Files.Files).map((key) => myFiles.push(Files.Files[key]));
    setFiles(myFiles);
    setMovies(Files.OMBD); */
    let myFiles = [];
    Object.keys(Files).map((key) => myFiles.push(Files[key]));
    setFiles(myFiles);
    setMovies(Movies);
    console.log("loaded dev data from files.");
  };
  /* async function fetchLastUpdateDate() {
    db.ref(`LastUpdateDate/`).on("value", function (snapshot) {
      var fbData = (snapshot.val() && snapshot.val()) || [];
      setLastUpdateDate(fbData);
    });
    console.log("fetched lastUpdateDate from firebase");
  } */
  async function fetchFiles() {
    // get json object of all movie files from firebase
    db.ref(`Files/`).on("value", function (snapshot) {
      var fbData = (snapshot.val() && snapshot.val()) || [];
      let myFiles = [];
      Object.keys(fbData).map((key) => myFiles.push(fbData[key]));
      setFiles(myFiles);
    });
    console.log("fetched Files from firebase");
  }
  async function fetchMovieInfo() {
    // get json object of all movie infos from firebase
    db.ref(`TMDB/`).on("value", function (snapshot) {
      var fbMovies = (snapshot.val() && snapshot.val()) || "not found any data";
      setMovies(fbMovies);
    });
    console.log("fetched Movie info from firebase");
  }
  //TODO: loading spinner
  return (
    <MovieContext.Provider value={{ files, movies, setIsPlaying, setShowMovieInfo, filter }}>
      <div className="App">
        <div className="menu">things</div>
        {showMovieInfo.length > 0 ? (
          <MovieInfo fileInfo={files[showMovieInfo[0]]} movieInfo={movies[showMovieInfo[1]]} setFilter={setFilter} />
        ) : isPlaying.length > 1 ? (
          <VideoPlayer src={`/movies/${isPlaying}`} />
        ) : Object.values(files).length > 1 && Object.values(movies).length ? (
          <MovieList />
        ) : (
          "loading..."
        )}
      </div>
    </MovieContext.Provider>
  );
};

export default App;
