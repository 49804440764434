import React, { useState, useContext, useEffect } from "react";
import { MovieContext } from "./App";
import Movie from "./Movie";
import Search from "./Search";
import useWindowDimensions from "./WindowDimensions";

const MovieList = () => {
  const MovieInfo = useContext(MovieContext);
  const [filtered, setFiltered] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [MoviesPerPage, setMoviesPerPage] = useState(4);
  const [swipe, setSwipe] = useState(false);
  const [swipePositionStart, setSwipePositionStart] = useState(0);
  const [swipePositionEnd, setSwipePositionEnd] = useState(0);
  const { height, width } = useWindowDimensions();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setPageIndex(0);
    calculateMoviesPerPage();
    console.log("used effect");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtered, height, width]);
  const calculateMoviesPerPage = () => {
    if (width < 415 || height < 415) {
      // mobile device
      setIsMobile(true);
      if (height < width) {
        // landscape
        if (height < 420 && width < 739) {
          /* iphone 6/7/8 & Plus & nexus 5X */
          setMoviesPerPage(3);
        } else {
          setMoviesPerPage(4);
        }
      } else {
        // portrait mode
        setMoviesPerPage(6);
      }
    } else {
      setIsMobile(false);
      const boxesAcross = Math.floor((width - 40 - 30) / (400 + 40));
      const boxesDown = Math.floor((height - 75) / (625 + 40));
      /* console.log(height);
      console.log(boxesDown); */
      setMoviesPerPage(boxesAcross * boxesDown);
    }
  };
  const handleScroll = (event) => {
    // on scroll down page up increases pageIndex state by number of boxes on screen
    if (event.deltaY < 0) {
      if (pageIndex - MoviesPerPage < 0) {
        setPageIndex(0);
      } else {
        setPageIndex(pageIndex - MoviesPerPage);
      }
    } else if (event.deltaY > 0) {
      if (pageIndex + MoviesPerPage >= filtered.length) {
        setPageIndex(filtered.length - MoviesPerPage);
      } else {
        setPageIndex(pageIndex + MoviesPerPage);
      }
    }
  };
  const handleKeyPress = (event) => {
    // key press down change page index up & key press up = page index down * without going out of bound 0-MovieInfo.files.length
    // TODO: get screen width in another function and calculate how many movie boxes per row there are (default right now is 6 for desktop)
    if (event.key === "ArrowDown") {
      if (pageIndex + MoviesPerPage >= filtered.length) {
        setPageIndex(filtered.length - MoviesPerPage);
      } else {
        setPageIndex(pageIndex + MoviesPerPage);
      }
    } else if (event.key === "ArrowUp") {
      if (pageIndex - MoviesPerPage < 0) {
        setPageIndex(0);
      } else {
        setPageIndex(pageIndex - MoviesPerPage);
      }
    }
  };
  const handleSlider = (sliderValue) => {
    setPageIndex(Math.floor(sliderValue / 6) * 6);
  };
  function startSwipe(e) {
    setSwipe(true);
    setSwipePositionStart(e.touches[0].screenY);
  }
  function checkSwipe(e) {
    setSwipePositionEnd(e.touches[0].screenY);
  }
  function doSwipe() {
    if (swipe && swipePositionStart !== 0 && swipePositionEnd !== 0 && swipePositionStart !== swipePositionEnd) {
      const move = Math.abs(swipePositionStart) - Math.abs(swipePositionEnd);
      if (Math.abs(move) > 50) {
        // minimum swipe distance to activate action
        if (move < 0) {
          if (pageIndex - MoviesPerPage < 0) {
            setPageIndex(0);
          } else {
            setPageIndex(pageIndex - MoviesPerPage);
          }
        } else if (move > 0) {
          if (pageIndex + MoviesPerPage >= filtered.length) {
            setPageIndex(filtered.length - MoviesPerPage);
          } else {
            setPageIndex(pageIndex + MoviesPerPage);
          }
        }
      }
    }
    setSwipePositionStart(0);
    setSwipePositionEnd(0);
    setSwipe(false);
  }
  return (
    <div className="movie-picker">
      <div className="header">
        <div className="header-title">
          <div className="logo">
            <img src="./logo192.png" alt="logo" />
          </div>
          <div style={{ marginTop: "-7px" }}>G Movies</div>
        </div>
        <Search filteredList={filtered} setViewList={setFiltered} />
      </div>
      <div className="list-area">
        {Object.keys(filtered).length >= 1 ? (
          <ul
            className={width > 1000 ? "movies-list desktop" : height < width ? "movies-list landscape" : "movies-list"}
            onWheel={(e) => handleScroll(e)}
            onKeyUp={(e) => handleKeyPress(e)}
            onTouchStart={(e) => startSwipe(e)}
            onTouchEnd={doSwipe}
            onTouchMove={(e) => checkSwipe(e)}
            tabIndex="0"
          >
            {Object.keys(filtered).map(
              (key) =>
                key >= pageIndex &&
                key < pageIndex + MoviesPerPage &&
                filtered[key].MovieApiId !== null && (
                  <Movie key={key} index={parseInt(key)} isMobile={isMobile} movie={MovieInfo.movies[filtered[key].MovieApiId]} file={filtered[key]} />
                )
            )}
          </ul>
        ) : (
          <div className="spinner">loading...</div>
        )}
        <div className="range-slider">
          <input
            type="range"
            value={pageIndex / MoviesPerPage}
            min="0"
            max={Math.ceil(filtered.length / MoviesPerPage - 1)}
            orient="vertical"
            className="slider"
            onChange={(e) => handleSlider(e.target.value)}
          />
          <div className="number">{filtered.length}</div>
        </div>
        {/* <output className="bubble">525</output> */}
      </div>
    </div>
  );
};
export default MovieList;
