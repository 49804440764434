import React, { useContext, useState, useEffect } from "react";
import { MovieContext } from "./App";
//TODO: horizonal mobile viewport support aka 3x1 instead of 2x3
const Search = (props) => {
  const MovieInfo = useContext(MovieContext);
  const [searchField, setSearchField] = useState("");
  const [genreFilter, setGenreFilter] = useState("");
  const [yearFilter, setYearFilter] = useState("");
  const [ratingFilter, setRatingFilter] = useState("");
  const [actorFilter, setActorFilter] = useState("");
  const [directorFilter, setDirectorFilter] = useState("");
  const [sortedBy, setSortedBy] = useState(2);
  const [genres, setGenres] = useState([]);
  const [actors, setActors] = useState([]);
  const [titles, setTitles] = useState([]);
  const [searchPicker, setSearchPicker] = useState(0);
  const [isRecentChecked, setIsRecentChecked] = useState(false);

  useEffect(() => {
    sortList(2);
    loadAutoCompleteLists();
    checkFilterStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [MovieInfo.files]);
  const checkFilterStatus = () => {
    if (Object.values(MovieInfo.filter).length > 0) {
      console.log(MovieInfo.filter);
      if (Object.keys(MovieInfo.filter)[0] === "actor") {
        setActorFilter(Object.values(MovieInfo.filter)[0]);
        applyFilters(4, Object.values(MovieInfo.filter)[0]);
      }
      if (Object.keys(MovieInfo.filter)[0] === "director") {
        setDirectorFilter(Object.values(MovieInfo.filter)[0]);
        applyFilters(5, Object.values(MovieInfo.filter)[0]);
      }
    }
  };
  const calcRecentDate = (useRecent = true) => {
    // get todays date and go back 30 days.
    if (useRecent) {
      const today = new Date();
      const recentDate = new Date();
      if (today.getMonth() === 0) {
        // january then go back to last year otherwise year is current year
        recentDate.setYear(today.getFullYear() - 1);
      }
      if (today.getDate() === 31) {
        recentDate.setDate(30);
      }
      if (today.getMonth() === 0) {
        recentDate.setMonth(11);
      } else {
        recentDate.setMonth(today.getMonth() - 1);
      }
      return recentDate;
    } else {
      return new Date("01/01/1900");
    }
  };
  const sortList = (sortType = 2, list = MovieInfo.files) => {
    // sort type 0:a->z,1:z->a,2:9-1rating,3:1-9rating... DEFAULT TO sort by rating high to low
    if (Object.values(MovieInfo.movies).length >= 1 && Object.values(list).length >= 1) {
      // only run if data has been downloaded.
      if (Object.keys(list).length >= 1) {
        // file list has been downloaded
        let myFiles = [];
        const sorted_index_list = Object.keys(list).sort((a, b) => {
          let MovieA = "",
            MovieB = "";
          if (list[a].MovieApiId !== undefined && list[b].MovieApiId !== undefined) {
            // only return files that have movie info attached to them
            if (sortType === 4 || sortType === 5) {
              // sort by date added
              MovieA = new Date(list[a]?.LastWriteTime);
              MovieB = new Date(list[b]?.LastWriteTime);
            } else if (sortType <= 1) {
              // sort by movie title and release year on tie
              MovieA = MovieInfo.movies[list[b]?.MovieApiId]?.Title;
              MovieB = MovieInfo.movies[list[a]?.MovieApiId]?.Title;
            } else if (sortType === 6 || sortType === 7) {
              // sort by popularity, more votes = more popular
              MovieA = parseInt(MovieInfo.movies[list[a]?.MovieApiId]?.imdbVotes.replaceAll(",", ""));
              MovieB = parseInt(MovieInfo.movies[list[b]?.MovieApiId]?.imdbVotes.replaceAll(",", ""));
            } else if (sortType === 8 || sortType === 9) {
              // sort by popularity, more votes = more popular
              MovieA = new Date(MovieInfo.movies[list[a]?.MovieApiId]?.Released);
              MovieB = new Date(MovieInfo.movies[list[b]?.MovieApiId]?.Released);
            } else {
              // sort by rating
              MovieA = MovieInfo.movies[list[a]?.MovieApiId]?.imdbRating;
              MovieB = MovieInfo.movies[list[b]?.MovieApiId]?.imdbRating;
            }
            if (MovieA === "N/A") {
              return MovieB;
            } else if (MovieB === "N/A") {
              return MovieA;
            } else if (MovieA === MovieB) {
              //? maybe get rid of .length and do this test on title too, test with movie : "the other woman"
              // movie name is the same then reference the year for sort... rating = ("7.5").length=3
              return MovieInfo.movies[list[a]?.MovieApiId]?.Year < MovieInfo.movies[list[b]?.MovieApiId]?.Year ? 1 : -1;
            } else {
              if (Math.abs(sortType % 2) === 1) {
                // test even or odd :
                return MovieA > MovieB ? 1 : -1;
              } else {
                return MovieA < MovieB ? 1 : -1;
              }
            }
          } else {
            return null;
          }
        });
        sorted_index_list.forEach((key) => {
          myFiles.push(list[key]);
        });
        props.setViewList(myFiles);
        setSortedBy(sortType);
        return myFiles;
      }
    }
  };
  const applyFilters = (filterSource, newFilter) => {
    // apply all filters with any filters that have been set to state
    // TODO: FIX rating filter removes all movies with a rating of N/A even after filter is blank, need some or statement to only apply when filter is set
    //? treat N/A as a rating of 1
    const list = sortList(sortedBy);
    let filtered = list
      .filter((x) => x.FileName.toLowerCase().includes(filterSource === 0 ? newFilter.toLowerCase() : searchField.toLowerCase()))
      .filter((x) => MovieInfo.movies[x?.MovieApiId]?.Genre.toLowerCase().includes(filterSource === 1 ? newFilter.toLowerCase() : genreFilter.toLowerCase()))
      .filter((x) => MovieInfo.movies[x?.MovieApiId]?.imdbRating !== "N/A" && MovieInfo.movies[x?.MovieApiId]?.imdbRating >= (filterSource === 2 ? newFilter : ratingFilter))
      .filter((x) => ((filterSource === 3 ? newFilter.length : yearFilter.length) === 4 ? MovieInfo.movies[x?.MovieApiId]?.Year === (filterSource === 3 ? newFilter : yearFilter) : x))
      .filter((x) => MovieInfo.movies[x?.MovieApiId]?.Actors.toLowerCase().includes(filterSource === 4 ? newFilter.toLowerCase() : actorFilter.toLowerCase()))
      .filter((x) => MovieInfo.movies[x?.MovieApiId]?.Director.toLowerCase().includes(filterSource === 5 ? newFilter.toLowerCase() : directorFilter.toLowerCase()))
      .filter((file) => (new Date(file?.LastWriteTime) > (filterSource === 6 ? calcRecentDate(newFilter) : calcRecentDate(isRecentChecked)) ? file : null));
    props.setViewList(filtered);
  };
  const filter = (phrase = "") => {
    // input box for search filters results to include only searched things
    setSearchField(phrase);
    applyFilters(0, phrase);
  };
  const filterByGenre = (genre = "") => {
    //TODO: limitations only does 1 genre
    setGenreFilter(genre);
    applyFilters(1, genre);
  };
  const filterByRating = (rating = 1) => {
    // show only items form the list that are above a set rating level
    if (rating.length === 3) {
      setSearchPicker(0);
    }
    setRatingFilter(rating);
    applyFilters(2, rating);
  };
  const filterByYear = (year = "") => {
    // show only items form the list that are in the year specified
    if (year.length === 4) {
      setSearchPicker(0);
    }
    setYearFilter(year);
    applyFilters(3, year);
  };
  const filterByActor = (actor = "") => {
    // show only items form the list that are in the year specified
    setActorFilter(actor);
    applyFilters(4, actor);
  };
  const toggleSort = (isAlpha) => {
    if (isAlpha === "alpha") {
      // sort by alphabet pressed
      if (sortedBy === 0) {
        // a->z current sort switches to z->a
        // already sorted az
        sortList(1, props.filteredList);
      } else {
        // not sorted az
        sortList(0, props.filteredList);
      }
    } else if (isAlpha === "rating") {
      // sort by rating pressed
      if (sortedBy === 2) {
        // rating 9->1 current then toggle to rating 1->9
        sortList(3, props.filteredList);
      } else {
        sortList(2, props.filteredList);
      }
    } else if (isAlpha === "date") {
      if (sortedBy === 4) {
        // date added recent->old current then toggle to rating old->recent
        sortList(5, props.filteredList);
      } else {
        sortList(4, props.filteredList);
      }
    } else if (isAlpha === "popular") {
      if (sortedBy === 6) {
        // date added recent->old current then toggle to rating old->recent
        sortList(7, props.filteredList);
      } else {
        sortList(6, props.filteredList);
      }
    } else if (isAlpha === "release") {
      if (sortedBy === 8) {
        // date added recent->old current then toggle to rating old->recent
        sortList(9, props.filteredList);
      } else {
        sortList(8, props.filteredList);
      }
    }
    setSearchPicker(0);
  };
  const loadAutoCompleteLists = () => {
    //TODO: sort lists alphabetically before adding into state
    Object.values(MovieInfo.files).forEach((file) => {
      if (MovieInfo.movies[file.MovieApiId]?.Genre !== undefined) {
        const genreList = MovieInfo.movies[file.MovieApiId]?.Genre.split(",");
        genreList.forEach((genre) => {
          if (genre.trim() !== "N/A") {
            if (!genres.includes(genre.trim())) {
              const list = genres;
              list.push(genre.trim());
              setGenres(list);
            }
          }
        });
      }
      if (MovieInfo.movies[file.MovieApiId]?.Actors !== undefined) {
        const actorList = MovieInfo.movies[file.MovieApiId]?.Actors.split(",");
        actorList.forEach((actor) => {
          if (!actors.includes(actor.trim())) {
            const list = actors;
            list.push(actor.trim());
            setActors(list);
          }
        });
      }
      if (MovieInfo.movies[file.MovieApiId]?.Title !== undefined) {
        if (!titles.includes(MovieInfo.movies[file.MovieApiId]?.Title)) {
          const list = titles;
          list.push(MovieInfo.movies[file.MovieApiId]?.Title);
          setTitles(list);
        }
      }
    });
  };
  const checkRecentMovies = () => {
    if (isRecentChecked) {
      // un checking recent selection needs to clear recent from filters
      applyFilters(6, false);
    } else {
      applyFilters(6, true);
    }
    setIsRecentChecked(!isRecentChecked);
  };
  const clearAllFilters = () => {
    setActorFilter("");
    setGenreFilter("");
    setRatingFilter("");
    setSearchField("");
    setYearFilter("");
    setDirectorFilter("");
    setIsRecentChecked(false);
    sortList();
  };
  return (
    <div className="search">
      {searchPicker === -1 && (
        <div>
          <button className={sortedBy === 0 || sortedBy === 1 ? "active" : ""} onClick={() => toggleSort("alpha")}>
            {sortedBy !== 1 ? "A➡Z" : "Z➡A"}
          </button>
          <button className={sortedBy === 4 || sortedBy === 5 ? "active" : ""} onClick={() => toggleSort("date")}>
            {sortedBy !== 4 ? "Added" : "Old➡Recent"}
          </button>
          <button className={sortedBy === 8 || sortedBy === 9 ? "active" : ""} onClick={() => toggleSort("release")}>
            {sortedBy !== 9 ? "Release" : "Old Releases"}
          </button>
          <button className={sortedBy === 6 || sortedBy === 7 ? "active" : ""} onClick={() => toggleSort("popular")}>
            {sortedBy !== 7 ? "Popularity" : "Least Popular"}
          </button>
          <button className={sortedBy === 2 || sortedBy === 3 ? "active" : ""} onClick={() => toggleSort("rating")}>
            {sortedBy !== 3 ? "9➡1" : "1➡9"}
            <span role="img" aria-label="rating">
              ⭐
            </span>
          </button>
          <button onClick={() => setSearchPicker(0)}>
            <span role="img" aria-label="go back">
              🔙
            </span>
          </button>
        </div>
      )}
      {searchPicker === 0 && (
        <div>
          <button onClick={() => setSearchPicker(1)}>Search</button>
          <button onClick={() => setSearchPicker(-1)}>Sort</button>
        </div>
      )}
      {searchPicker === 1 && (
        <div>
          <button onClick={() => setSearchPicker(2)}>Genre</button>
          <button onClick={() => setSearchPicker(3)}>Actor</button>
          <button onClick={() => setSearchPicker(4)}>Title</button>
          <button onClick={() => setSearchPicker(5)}>Year</button>
          <button onClick={() => setSearchPicker(6)}>Rating</button>
          <button onClick={() => setSearchPicker(0)}>
            <span role="img" aria-label="go back">
              🔙
            </span>
          </button>
        </div>
      )}
      {searchPicker === 2 && (
        <div className="searchBox">
          <input
            className="text-input"
            type="text"
            name="genreBox"
            label="genre box"
            value={genreFilter}
            onChange={(e) => filterByGenre(e.target.value)}
            placeholder="GENRE Filter"
          ></input>
          <button onClick={() => setSearchPicker(0)}>⏮</button>
          <ul className="autocomplete-list">
            {genres.map((genre) => (
              <li
                key={genre}
                onClick={() => {
                  filterByGenre(genre);
                  setSearchPicker(0);
                }}
              >
                {genre.toLowerCase().includes(genreFilter.toLowerCase()) && genreFilter !== "" && genre}
              </li>
            ))}
          </ul>
        </div>
      )}
      {searchPicker === 3 && (
        <div className="searchBox">
          <input
            className="text-input"
            type="text"
            name="actorBox"
            label="actor box"
            value={actorFilter}
            onChange={(e) => filterByActor(e.target.value)}
            placeholder="ACTOR Filter"
          ></input>
          <button onClick={() => setSearchPicker(0)}>⏮</button>
          <ul className="autocomplete-list">
            {actors.map((actor) => (
              <li
                key={actor}
                onClick={() => {
                  filterByActor(actor);
                  setSearchPicker(0);
                }}
              >
                {actor.toLowerCase().includes(actorFilter.toLowerCase()) && actorFilter !== "" && actor}
              </li>
            ))}
          </ul>
        </div>
      )}
      {searchPicker === 4 && (
        <div className="searchBox">
          <input className="text-input" type="text" name="searchBox" label="search box" value={searchField} onChange={(e) => filter(e.target.value)} placeholder="TITLE Search"></input>
          <button onClick={() => setSearchPicker(0)}>⏮</button>
          <ul className="autocomplete-list">
            {titles.map((title) => (
              <li
                key={title}
                onClick={() => {
                  filter(title);
                  setSearchPicker(0);
                }}
              >
                {title.toLowerCase().includes(searchField.toLowerCase()) && searchField !== "" && title}
              </li>
            ))}
          </ul>
        </div>
      )}
      {searchPicker === 5 && (
        <div className="searchBox">
          <input className="text-input" type="text" name="yearBox" label="year box" value={yearFilter} onChange={(e) => filterByYear(e.target.value)} placeholder="YEAR Filter"></input>
          <button onClick={() => setSearchPicker(0)}>⏮</button>
        </div>
      )}
      {searchPicker === 6 && (
        <div className="searchBox">
          <input
            className="text-input"
            type="text"
            name="ratingBox"
            label="rating box"
            value={ratingFilter}
            onChange={(e) => filterByRating(e.target.value)}
            placeholder="RATING Filter"
          ></input>
          <button onClick={() => setSearchPicker(0)}>⏮</button>
        </div>
      )}
      {searchPicker === 0 && (
        <div>
          <div className="filters-shown">
            {`${genreFilter} ${actorFilter} ${directorFilter} ${yearFilter} ${ratingFilter} ${searchField}`}
            <div /* onClick={() => checkRecentMovies()} */>
              <input type="checkbox" checked={isRecentChecked ? true : false} onChange={checkRecentMovies}></input>
              <label>30 Days</label>
            </div>
            {(genreFilter || actorFilter || yearFilter || ratingFilter || searchField || directorFilter) && (
              <span role="img" aria-label="clear all filters" onClick={() => clearAllFilters()}>
                ❌
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default Search;
